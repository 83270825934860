<template>
    <div>
        <el-form data-cy="user-form" v-if="USER_PERMISSIONS && formFieldsRoute" label-width="120px" :rules="rules" :model="formFieldsRoute" ref="form" label-position="top" v-loading="loading">
            <h1 v-if="formFieldsRoute.id">Edit User</h1>
            <h1 v-else>New User</h1>

            <!-- User Info -->
            <div class="card mt-4">
                <div class="card-title">User Info</div>
                <div>
                    <div class="flex flex-wrap gap-4">
                        <el-form-item class="flex-grow" label="First Name" prop="firstName">
                            <el-input data-cy="firstName" v-model="formFieldsRoute.firstName" tabindex="1"></el-input>
                        </el-form-item>
                        <el-form-item class="flex-grow" label="Last Name" prop="lastName">
                            <el-input data-cy="lastName" v-model="formFieldsRoute.lastName" tabindex="2"></el-input>
                        </el-form-item>
                    </div>
                    <div class="flex flex-wrap gap-4">
                        <el-form-item class="flex-grow" label="Email" prop="email">
                            <el-input data-cy="email" v-model="formFieldsRoute.email" tabindex="3"></el-input>
                        </el-form-item>
                        <el-form-item class="flex-grow" label="Phone" prop="phone">
                            <InputPhoneNumber data-cy="phone" v-model="formFieldsRoute.phone"/>
                        </el-form-item>
                        <el-form-item v-if="!$store.getters.isZohoCrmDisabled" class="flex-grow" v-show="$store.getters.isSystemAdmin" label="Zoho CRM Contact ID" prop="zohoCrmContactRecordId">
                            <el-input v-model="formFieldsRoute.zohoCrmContactRecordId" disabled></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>

            <!-- Notifications -->
            <div class="card">        
                <div class="card-title">Notifications</div>
                <div class="card-subtitle">Choose which notifications this user receives via SMS and email. In-App notifications cannot be disabled.</div>
                <div class="grid grid-cols-12 grid-flow-rows gap-4 items-center justify-center font-medium">
                    <div class=" hidden md:block md:col-span-6 lg:col-span-9 2xl:col-span-9"></div>
                    <div class=" hidden md:block md:col-span-2 lg:col-span-1 2xl:col-span-1">In-App</div>
                    <div class=" hidden md:block md:col-span-2 lg:col-span-1 2xl:col-span-1">SMS</div>
                    <div class=" hidden md:block md:col-span-2 lg:col-span-1 2xl:col-span-1">Email</div>
                </div>
                <div class="grid grid-cols-12 grid-flow-rows gap-4 border-b last:border-b-0 border-gray-300 py-4 items-center" v-for="option in notificationOptions" :key="option.title">
                    <div class="col-span-12 md:col-span-6 lg:col-span-9 2xl:col-span-9 break-normal">
                        <div class="text-black">{{option.title}}</div>
                        <div class="font-medium text-gray-600 text-sm">{{option.description}}</div>
                    </div>
                    <div class="col-span-12 md:col-span-2 lg:col-span-1 2xl:col-span-1">
                        <span class="block md:hidden">In-App</span><el-switch disabled :value="true"></el-switch>
                    </div>
                    <div class="col-span-12 md:col-span-2 lg:col-span-1 2xl:col-span-1">
                        <span class="block md:hidden">SMS</span><el-switch v-model="formFieldsRoute[option.smsField]"></el-switch>
                    </div>
                    <div class="col-span-12 md:col-span-2 lg:col-span-1 2xl:col-span-1">
                        <span class="block md:hidden">Email</span><el-switch v-model="formFieldsRoute[option.emailField]"></el-switch>
                    </div>
                </div>
            </div>

            <!-- Account -->
            <div class="card">    
                <div class="card-title">Account</div>
                <div class="card-subtitle">Control login and admin access for this user.</div>

                <div data-cy="uf-account-control-access-item" class="grid grid-cols-12 gap-4 border-b last:border-b-0 border-gray-300 py-4 items-center" v-for="option in accountOptions" :key="option.key">
                    <div class="col-span-12 md:col-span-10 lg:col-span-11 2xl:col-span-11 break-normal">
                        <div class="text-black">{{option.title}}</div>
                        <div class="font-medium text-gray-600 text-sm pr-8">{{option.description}}</div>
                    </div>
                    <div class="col-span-12 md:col-span-2 lg:col-span-1 2xl:col-span-1" >
                        <el-switch :id="option.key" @change="updateToggles(option.field)" :disabled="!formFieldsRoute.permissionLogin && option.field != 'permissionLogin'" v-model="formFieldsRoute[option.field]"></el-switch>
                    </div>
                </div>
            </div>

            <!-- Permissions -->
            <div class="card" :class="{'opacity-50': !formFieldsRoute.permissionLogin || formFieldsRoute.permissionFullAccess}">
                <div class="card-title">Permissions</div>
                <div class="card-subtitle">Customize individual permissions for this user.</div>

                <div data-cy="uf-permission-item" class="grid grid-cols-12 gap-4 border-b last:border-b-0 py-4 items-center" v-for="option in permissionOptions" :key="option.key">
                    <div class="col-span-12 md:col-span-10 lg:col-span-11 2xl:col-span-11 break-normal">
                        <div class="text-black">{{option.title}}</div>
                        <div class="font-medium text-gray-600 text-sm pr-8">{{option.description}}</div>
                    </div>
                    <div class="col-span-12 md:col-span-2 lg:col-span-1 2xl:col-span-1" >
                        <el-switch :id="option.key" @change="updateToggles(option.field)" :disabled="!formFieldsRoute.permissionLogin || formFieldsRoute.permissionFullAccess" v-model="formFieldsRoute[option.field]"></el-switch>
                    </div>
                </div>
            </div>

            <div v-if="!formFieldsRoute.id" class=" text-right mt-8 -mb-4 text-sm font-medium text-gray-600">
                Hera will email this user a temporary password to get started.
            </div>
            <div class=" text-right mt-8">
                <ButtonGroupWrapper>
                    <template slot-scope="{disabled, setDisabled}">
                        <el-button
                            :disabled="disabled || loading"
                            @click="$router.go(-1)"
                        >
                            Cancel
                        </el-button>
                        <ButtonWrapper
                            v-if="formFieldsRoute.id"
                            type="primary"
                            data-cy="update-button"
                            :clickHandler="confirmUpdateUser"
                            :disabled="disabled || loading"
                            @cb-start="setDisabled(true)"
                            @cb-end="setDisabled(false)"
                        >
                            Update
                        </ButtonWrapper>
                        <ButtonWrapper
                            v-else
                            type="primary"
                            :clickHandler="createUser"
                            :disabled="disabled || loading"
                            @cb-start="setDisabled(true)"
                            @cb-end="setDisabled(false)"
                        >
                            Create
                        </ButtonWrapper>
                    </template>
                </ButtonGroupWrapper>
          </div>
        </el-form>

        <el-form v-else-if="formFields" label-width="120px" :rules="rules" :model="formFields" ref="form" >
            <el-form-item label="First Name" prop="firstName">
                <el-input v-model="formFields.firstName"></el-input>
            </el-form-item>
            <el-form-item label="Last Name" prop="lastName">
                <el-input v-model="formFields.lastName"></el-input>
            </el-form-item>
            <el-form-item label="Phone" prop="phone">
                <InputPhoneNumber v-model="formFields.phone"/>
            </el-form-item>
            <el-form-item label="Email" prop="email">
                <el-input v-model="formFields.email"></el-input>
            </el-form-item>
            <el-form-item label="Role" prop="role">
                <el-select v-model="formFields.role" placeholder="Role">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <el-tooltip class="item" effect="dark" placement="top-start" width="100px">
                    <div slot="content">
                        <div class="mb-2">Admin: Access to all of Hera</div>
                        <div class="mb-2">Dispatcher: Excludes access to settings</div>
                        <div class="mb-2">Supervisor: Excludes access to settings and accident information </div>
                        <div>Team Lead: Excludes access to settings, accident, medical, and OSHA information</div>
                    </div>
                    <div class="text-gray-700 text-xs inline ml-4"><i class="uil uil-question-circle text-base"></i></div>
                </el-tooltip>
            </el-form-item>
            <el-form-item class="settingsSwitch" label="" prop="permissionLogin">
                <el-switch v-model="formFields.permissionLogin"></el-switch> Is Active?
            </el-form-item>
            <p class="py-3 text-lg">Notifications</p>
            <p class="pb-5 text-xs text-gray-600">Receieve notifications for...</p>

            <div class="flex justify-left">
                <p class="w-30"></p>
                <p class="ml-26 text-right">In App</p>
                <p class="ml-26 text-right">SMS</p>
                <p class="ml-24 text-right">Emails</p>
            </div>

            <div class="flex">
                <p class="pt-2 w-30">New Tasks</p>
                <el-form-item>
                    <el-checkbox disabled :value="true"></el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="formFields.receiveSmsTaskAssignments"></el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="formFields.receiveEmailTaskAssignments"></el-checkbox>
                </el-form-item>
            </div>

            <div class="flex">
                <p class="pt-2 w-30">Task Reminders</p>
                <el-form-item>
                    <el-checkbox disabled :value="true"></el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="formFields.receiveSmsTaskReminders"></el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="formFields.receiveEmailTaskReminders"></el-checkbox>
                </el-form-item>
            </div>
        </el-form>

        <el-dialog
          id="show-popup-update-email"
          title="Warning"
          :visible.sync="showPopupUpdateEmail"
          width="30%"
          :show-close="false"
          :close-on-click-modal="false"
          >
          <span >You are about to update the email address for this user. <span v-if="allowResendEmail">Would you like to resend an email with their temporary password to the new email address?</span></span>
          <span slot="footer" class="dialog-footer">
            <ButtonGroupWrapper>
                <template slot-scope="{disabled, setDisabled}">
                    <el-button
                        size="small"
                        :disabled="disabled"
                        @click="showPopupUpdateEmail=false"
                    >
                        Cancel
                    </el-button>
                    <ButtonWrapper
                        v-if="allowResendEmail"
                        type="primary"
                        size="small"
                        :clickHandler="updateUser"
                        :handlerArgs="[false, true, true]"
                        :disabled="disabled"
                        @cb-start="setDisabled(true)"
                        @cb-end="setDisabled(false)"
                    >
                        Update & Resend
                    </ButtonWrapper>
                    <ButtonWrapper
                        type="primary"
                        size="small"
                        :clickHandler="updateUser"
                        :handlerArgs="[false, true, false]"
                        :disabled="disabled"
                        @cb-start="setDisabled(true)"
                        @cb-end="setDisabled(false)"
                    >
                        Update
                    </ButtonWrapper>
                </template>
            </ButtonGroupWrapper>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import { getTenant } from '../query'
import Validator from '@/utilities/FormValidator'
import { cleanPhoneNumber, phoneNumberFormatUS } from '@/utilities/phoneNumberFormat'
import { createUser, updateUser, deleteUser } from '@/graphql/mutations'
import { updateZohoAccountTenant, updateZohoAccountUser } from '../../../../components/Zoho/zoho'
import { getUser } from '../query'
import { mapMutations } from 'vuex'
import { Auth, API } from 'aws-amplify';
import { createNotification } from '@/graphql/mutations'
import  InputPhoneNumber  from '@/components/InputPhoneNumber.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper/ButtonWrapper';
import ButtonGroupWrapper from '@/components/shared/ButtonGroupWrapper/ButtonGroupWrapper';

export default {
  
    name: 'UserForm',
    props:['formFields' ],
    components: {
        InputPhoneNumber,
        ButtonWrapper,
        ButtonGroupWrapper,
    },
    data(){
        return{
            keyPhoneNumber:'',
            loading: false,
            group: '',
            tenantId: '',
            formFieldsRoute: {},
            editUserDataPrev: {},
            editPrevEmail: '',
            phone: null,
            results: null,
            USER_PERMISSIONS: process.env.VUE_APP_USER_PERMISSIONS === "1",
            options: [
                {
                    value: 'admin',
                    label: 'Admin'
                },
                {
                    value: 'dispatcher',
                    label: 'Dispatcher'
                },
                {
                    value: 'supervisor',
                    label: 'Supervisor'
                },
                {
                    value: 'teamlead',
                    label: 'Team Lead'
                }
            ],
            notificationOptions: [
                { title: 'New Task', description: 'Receive notifications when new tasks are created for you.', smsField: 'receiveSmsTaskAssignments', emailField: 'receiveEmailTaskAssignments'},
                { title: 'Task Reminders', description: 'Receive notifications when upcoming tasks are due.', smsField: 'receiveSmsTaskReminders', emailField: 'receiveEmailTaskReminders'}
            ],
            accountOptions: [
                { key: 'heraLogin', title: 'Can log in to Hera', description: 'Can access all other Hera features including, Daily Rostering, Performance & Coaching, '+this.$t('label.associate')+' Management, Vehicle Management, Tasks & Reports, etc.', field: 'permissionLogin'},
                { key: 'fullAccess', title: 'Has Full Admin Access', description: 'In addition to all the permissions below, users can access and edit all Hera settings, including payment info, invoices, company details, users, user permissions, '+this.$t('label.associate')+' Performance Report settings, and message logs. To disable individual permissions, Full Admin Access must be disabled.',  field: 'permissionFullAccess'}
            ],
            permissionOptions: [
                {   key: 'labels', 
                    title: "Company Settings: Manage Labels", 
                    description: `The user can create, edit, and delete Labels, which can be applied to ${this.$t('label.associates')} and Vehicles. (Note: All users with access to "${this.$t('label.associate')} Management" and "Vehicle Management" will be able to apply and remove Labels to and from ${this.$t('label.associates')} and Vehicles; but they must have this permission to create and edit Label names and details.)`,  
                    field: 'permissionManageLabels'
                },
                { 
                    key: 'documents', 
                    title: `Associate Management: ${this.$t('label.associate')} Documents`, 
                    description: `The user can view, create, edit, and delete ${this.$t('label.associates_')} documents.`, 
                    field: 'permissionDocuments' 
                },
                { 
                    key: 'counselings', 
                    title: 'Company Settings: Manage Counseling Templates & Settings', 
                    description: 'The user can add, edit, and delete Counseling Templates by going to Company Settings.', 
                    field: 'permissionCounselings' 
                },
                {
                    key: 'manageCounselings',
                    title: 'Performance & Coaching: Counselings',
                    description: "The user can access, create, and edit Counselings and related reports in Hera. Without this permission, the user will see a message under the 'Counseings' sub-tab of the 'Performance & Coaching' tab that instructs them to contact their administrator for access.",
                    field: 'permissionManageCounselings',
                },
                { 
                    key: 'accidentReports', 
                    title: 'Vehicle & Associate Management: Incident Records', 
                    description: 'The user can view, create, edit, and delete Accident Records.', 
                    field: 'permissionAccidents' 
                },
                { 
                    key: 'injuryReports', 
                    title: 'Associate Management: Injury Records', 
                    description: 'The user can view, create, edit, and delete Injury Records.', 
                    field: 'permissionInjuries' 
                },
                { 
                    key: 'drugTests', 
                    title: 'Associate Management: Drug Tests', 
                    description: `The user can view, create, edit, and delete ${this.$t('label.associates_')} drug tests.`, 
                    field: 'permissionDrugTests' 
                },
                { 
                    key: 'dailyRostering', 
                    title: 'Module: Daily Rostering', 
                    description: "The user can access the Daily Rostering module in Hera. Without this permission, the user will see a message under the 'Daily Rostering' tab that instructs them to contact their administrator for access.", 
                    field: 'permissionDailyRostering' 
                },
                { 
                    key: 'heraMessenger', 
                    title: 'Module: Hera Messenger', 
                    description: `The user can access the Hera Messenger module in Hera. They can receive notifications in Hera, read incoming messages, and send individual messages to Associates. Without this permission, the user will see a message under the 'Hera Messenger' tab that instructs them to contact their administrator for access. (Note: Even without this permission, the user will be able to send broadcast messages if they have the 'Module: Daily Rostering' permission.)`, 
                    field: 'permissionMessenger' 
                },
                { 
                    key: 'performanceCoaching', 
                    title: 'Module: Performance & Coaching', 
                    description: "The user can access the Performance & Coaching module in Hera. Without this permission, the user will see a message under the 'Performance & Coaching' tab that instructs them to contact their administrator for access, and they will not have access to Performance & Coaching reports in the Tasks & Reports module.", 
                    field: 'permissionPerformanceCoaching' 
                },
                {
                    key: 'customLists',
                    title: 'Company Settings: Manage Custom Lists',
                    description: "The user can add, edit, and delete options for all the Custom Lists available in Hera by going to Company Settings. This permission automatically includes all permissions that allow users to add options to Custom Lists.",
                    field: 'permissionCustomLists'
                },
                { 
                    key: 'DAManagement', 
                    title: `Module: ${this.$t('label.associate')} Management`, 
                    description: `The user can access the Associate Management module in Hera. Without this permission, the user will see a message under the 'Associate Management' tab that instructs them to contact their administrator for access, and they will not have access to Associate Management reports in the Tasks & Reports module.`, 
                    field: 'permissionDAManagement' 
                },
                { 
                    key: 'vehicleManagement', 
                    title: 'Module: Vehicle Management', 
                    description: "The user can access the Vehicle Management module in Hera. Without this permission, the user will see a message under the 'Vehicle Management' tab that instructs them to contact their administrator for access.", 
                    field: 'permissionVehicleManagement' 
                },
                { 
                    key: 'tasksReports', 
                    title: 'Module: Tasks & Reports', 
                    description: "The user can access the Daily Rostering module in Hera. Without this permission, the user will see a message under the 'Daily Rostering' tab that instructs them to contact their administrator for access.", 
                    field: 'permissionTasksReports' 
                },
                { 
                    key: 'messageTemplate', 
                    title: 'Company Settings: Manage Message Templates', 
                    description: "The user can create, edit, and delete Message Templates, which can be used in Hera Messenger.", 
                    field: 'permissionMessageTemplate' 
                },
                { 
                    key: 'vehiclePhotoLogs', 
                    title: 'Company Settings: Manage Vehicle Photo Log Settings', 
                    description: "The user can edit company-wide settings for Vehicle Photo Logs by going to Company Settings.", 
                    field: 'permissionVehiclePhotoLogs' 
                }

            ],
            rules: {
                firstName: [
                    { required: true, message: 'First Name is required', trigger: 'blur' },
                ],
                lastName: [
                    { required: true, message: 'Last Name is required', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: 'Please enter a valid email', trigger: 'blur', validator: Validator.email },
                ],
                role: [
                    { required: true, message: 'Role is required', trigger: 'blur' },
                ],
                phone: [
                    { validator: Validator.validatePhoneNumberUser,  trigger: 'blur', params: { daId: this.$route.params.id, group: this.$route.params.group } }
                ]
            },
            showPopupUpdateEmail: false,
            allowResendEmail: false
        }
    },

    async mounted(){
       
        this.loading = true
        this.permissionOptions.sort((a, b) => a.title.localeCompare(b.title));
        if(this.USER_PERMISSIONS){
            try{
                if(this.$route.params.id){
                    let user = await this.api(getUser, { id: this.$route.params.id })
                    user = user.data.getUser
                   
                    //set default value to true is persmisson is null
                    user.permissionDailyRostering = user.permissionDailyRostering ?? true
                    user.permissionMessenger = user.permissionMessenger ?? true
                    user.permissionPerformanceCoaching = user.permissionPerformanceCoaching ?? true
                    user.permissionDAManagement = user.permissionDAManagement ?? true
                    user.permissionManageLabels = user.permissionManageLabels ?? true
                    user.permissionMessageTemplate = user.permissionMessageTemplate ?? true
                    user.permissionVehicleManagement = user.permissionVehicleManagement ?? true
                    user.permissionTasksReports = user.permissionTasksReports ?? true
                    user.permissionVehiclePhotoLogs = user.permissionVehiclePhotoLogs ?? true

                    this.formFieldsRoute = {...user}
                    this.formFieldsRoute.phone = phoneNumberFormatUS(this.formFieldsRoute.phone)
                    this.editUserDataPrev = {...user}
                    this.editPrevEmail = user.email
                    this.editPrevRole = user.role
                }
                else{
                    this.formFieldsRoute = {
                        permissionLogin: true,
                        permissionFullAccess: true,
                        permissionDocuments: true,
                        permissionCounselings: true,
                        permissionManageCounselings: true,
                        permissionAccidents: true,
                        permissionInjuries: true,
                        permissionDrugTests: true,
                        permissionDailyRostering: true,
                        permissionMessenger: true,
                        permissionPerformanceCoaching: true,
                        permissionDAManagement: true,
                        receiveSmsTaskReminders: true,
                        receiveEmailTaskReminders: true,
                        receiveSmsTaskAssignments: true,
                        receiveEmailTaskAssignments: true,
                        permissionDailyRostering: true,
                        permissionCustomLists: true,
                        permissionPerformanceCoaching: true,
                        permissionVehicleManagement: true,
                        permissionTasksReports: true,
                        permissionMessageTemplate: true,
                        permissionVehiclePhotoLogs: true,
                        permissionManageLabels: true,
                    }

                }
                this.group = this.$route.params.group
                this.tenantId = this.$route.params.tenantId
                this.companyName = this.$route.params.companyName
            }
            catch(e){
                console.error(e)
                this.displayUserError(e)
            }
            finally{
                this.loading = false
            }
        }
    },
    
    methods:{
        ...mapMutations([
            'setSessionInfo', 'setUserInfo'
        ]),  

        async updateToggles(field){
            if(field === 'permissionFullAccess' && this.formFieldsRoute.permissionFullAccess == true){
                this.formFieldsRoute.permissionDocuments = true
                this.formFieldsRoute.permissionCounselings = true
                this.formFieldsRoute.permissionManageCounselings = true
                this.formFieldsRoute.permissionAccidents = true
                this.formFieldsRoute.permissionInjuries = true
                this.formFieldsRoute.permissionDrugTests = true
                this.formFieldsRoute.permissionDailyRostering = true
                this.formFieldsRoute.permissionMessenger = true
                this.formFieldsRoute.permissionPerformanceCoaching = true
                this.formFieldsRoute.permissionDAManagement = true
                this.formFieldsRoute.permissionCustomLists = true
                this.formFieldsRoute.permissionManageLabels = true
                this.formFieldsRoute.permissionMessageTemplate = true                
                this.formFieldsRoute.permissionTasksReports = true
                this.formFieldsRoute.permissionVehicleManagement = true
                this.formFieldsRoute.permissionVehiclePhotoLogs = true
            }
            else if(field === 'permissionLogin' && this.formFieldsRoute.permissionLogin == false){
                this.formFieldsRoute.permissionFullAccess = false
                this.formFieldsRoute.permissionDocuments = false
                this.formFieldsRoute.permissionCounselings = false
                this.formFieldsRoute.permissionManageCounselings = false
                this.formFieldsRoute.permissionAccidents = false
                this.formFieldsRoute.permissionInjuries = false
                this.formFieldsRoute.permissionDrugTests = false
                this.formFieldsRoute.permissionDailyRostering = false
                this.formFieldsRoute.permissionMessenger = false
                this.formFieldsRoute.permissionPerformanceCoaching = false
                this.formFieldsRoute.permissionCustomLists = false
                this.formFieldsRoute.permissionDAManagement = false
                this.formFieldsRoute.permissionManageLabels = false
                this.formFieldsRoute.permissionMessageTemplate = false
                this.formFieldsRoute.permissionTasksReports = false
                this.formFieldsRoute.permissionVehicleManagement = false
                this.formFieldsRoute.permissionVehiclePhotoLogs = false
            }
            if((field === 'permissionFullAccess' && this.formFieldsRoute.permissionFullAccess == false) || 
               (field === 'permissionLogin' && this.formFieldsRoute.permissionLogin == false)){
                // check for at least one other full access user
                let tenantId = this.$store.state.userInfo.tenant.id;
                const tenant = await this.api(getTenant, { id: tenantId })
                let users = tenant.data.getTenant.users.items

                let fullAccessUsers = users.filter(user =>{
                    return user.permissionFullAccess
                })
                let loginUsers = users.filter(user =>{
                    return user.permissionLogin
                })

                let numFullAccessUsers = fullAccessUsers.length
                let numLoginUsers = loginUsers.length

                if(numLoginUsers == 1 && loginUsers[0].id == this.formFieldsRoute.id && field === 'permissionLogin'){
                    this.formFieldsRoute.permissionLogin = true
                    if(numFullAccessUsers == 1 && fullAccessUsers[0].id == this.formFieldsRoute.id){
                        this.formFieldsRoute.permissionFullAccess = true
                        this.formFieldsRoute.permissionDocuments = true
                        this.formFieldsRoute.permissionCounselings = true
                        this.formFieldsRoute.permissionManageCounselings = true
                        this.formFieldsRoute.permissionAccidents = true
                        this.formFieldsRoute.permissionInjuries = true
                        this.formFieldsRoute.permissionDrugTests = true
                        this.formFieldsRoute.permissionDailyRostering = true
                        this.formFieldsRoute.permissionMessenger = true
                        this.formFieldsRoute.permissionPerformanceCoaching = true
                        this.formFieldsRoute.permissionDAManagement = true
                        this.formFieldsRoute.permissionCustomLists = true
                        this.formFieldsRoute.permissionManageLabels = true
                        this.formFieldsRoute.permissionMessageTemplate = true
                        this.formFieldsRoute.permissionTasksReports = true
                        this.formFieldsRoute.permissionVehicleManagement = true  
                        this.formFieldsRoute.permissionVehiclePhotoLogs = true                      
                    }
                    this.displayUserError('Your account must have at least one user with login privileges.')
                }
                else if(numFullAccessUsers == 1 && fullAccessUsers[0].id == this.formFieldsRoute.id){
                    this.formFieldsRoute.permissionFullAccess = true
                    this.formFieldsRoute.permissionDocuments = true
                    this.formFieldsRoute.permissionCounselings = true
                    this.formFieldsRoute.permissionManageCounselings = true
                    this.formFieldsRoute.permissionAccidents = true
                    this.formFieldsRoute.permissionInjuries = true
                    this.formFieldsRoute.permissionDrugTests = true
                    this.formFieldsRoute.permissionDailyRostering = true
                    this.formFieldsRoute.permissionMessenger = true
                    this.formFieldsRoute.permissionPerformanceCoaching = true
                    this.formFieldsRoute.permissionDAManagement = true
                    this.formFieldsRoute.permissionCustomLists = true
                    this.formFieldsRoute.permissionManageLabels = true
                    this.formFieldsRoute.permissionMessageTemplate = true
                    this.formFieldsRoute.permissionTasksReports = true
                    this.formFieldsRoute.permissionVehicleManagement = true 
                    this.formFieldsRoute.permissionVehiclePhotoLogs = true
                    if(field === 'permissionLogin') this.formFieldsRoute.permissionLogin = true
                    this.displayUserError('Your account must have at least one user with Full Access privileges.')
                }
                
            }
            
        },

        async confirmUpdateUser(){
            // warn about editing your own permissions 
            if(this.formFieldsRoute.id == this.$store.state.userInfo.id && (
                this.editUserDataPrev.permissionLogin != this.formFieldsRoute.permissionLogin ||
                this.editUserDataPrev.permissionFullAccess != this.formFieldsRoute.permissionFullAccess ||
                this.editUserDataPrev.permissionDocuments != this.formFieldsRoute.permissionDocuments ||
                this.editUserDataPrev.permissionCounselings != this.formFieldsRoute.permissionCounselings ||
                this.editUserDataPrev.permissionManageCounselings != this.formFieldsRoute.permissionManageCounselings ||
                this.editUserDataPrev.permissionAccidents != this.formFieldsRoute.permissionAccidents ||
                this.editUserDataPrev.permissionInjuries != this.formFieldsRoute.permissionInjuries ||
                this.editUserDataPrev.permissionDrugTests != this.formFieldsRoute.permissionDrugTests ||
                this.editUserDataPrev.permissionDailyRostering != this.formFieldsRoute.permissionDailyRostering ||
                this.editUserDataPrev.permissionMessenger != this.formFieldsRoute.permissionMessenger ||
                this.editUserDataPrev.permissionPerformanceCoaching != this.formFieldsRoute.permissionPerformanceCoaching ||
                this.editUserDataPrev.permissionTasksReports != this.formFieldsRoute.permissionTasksReports ||
                this.editUserDataPrev.permissionVehicleManagement != this.formFieldsRoute.permissionVehicleManagement ||
                this.editUserDataPrev.permissionCustomLists != this.formFieldsRoute.permissionCustomLists ||
                this.editUserDataPrev.permissionDAManagement != this.formFieldsRoute.permissionDAManagement ||
                this.editUserDataPrev.permissionManageLabels != this.formFieldsRoute.permissionManageLabels
            )){
                try {
                    await this.$confirm('You are about to update the account access or permissions for the current user. This will log you out to apply the changes. Continue?', 'Warning', {
                        confirmButtonText: 'Update',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });
                    await this.updateUser(true, false, false);
                }
                catch (error) {
                    console.error("canceled update");
                    return;
                }
            }
            else if(
                this.editUserDataPrev.permissionLogin != this.formFieldsRoute.permissionLogin ||
                this.editUserDataPrev.permissionFullAccess != this.formFieldsRoute.permissionFullAccess ||
                this.editUserDataPrev.permissionDocuments != this.formFieldsRoute.permissionDocuments ||
                this.editUserDataPrev.permissionCounselings != this.formFieldsRoute.permissionCounselings ||
                this.editUserDataPrev.permissionManageCounselings != this.formFieldsRoute.permissionManageCounselings ||
                this.editUserDataPrev.permissionAccidents != this.formFieldsRoute.permissionAccidents ||
                this.editUserDataPrev.permissionInjuries != this.formFieldsRoute.permissionInjuries ||
                this.editUserDataPrev.permissionDrugTests != this.formFieldsRoute.permissionDrugTests ||
                this.editUserDataPrev.permissionDailyRostering != this.formFieldsRoute.permissionDailyRostering ||
                this.editUserDataPrev.permissionMessenger != this.formFieldsRoute.permissionMessenger ||
                this.editUserDataPrev.permissionPerformanceCoaching != this.formFieldsRoute.permissionPerformanceCoaching ||
                this.editUserDataPrev.permissionTasksReports != this.formFieldsRoute.permissionTasksReports ||
                this.editUserDataPrev.permissionVehicleManagement != this.formFieldsRoute.permissionVehicleManagement ||
                this.editUserDataPrev.permissionCustomLists != this.formFieldsRoute.permissionCustomLists ||
                this.editUserDataPrev.permissionDAManagement != this.formFieldsRoute.permissionDAManagement ||
                this.editUserDataPrev.permissionManageLabels != this.formFieldsRoute.permissionManageLabels
            
            ){
                try {
                    await this.$confirm('You are about to update the account access or permissions for another user. If they are currently logged in, they will be logged out to apply the changes. Continue?', 'Warning', {
                        confirmButtonText: 'Update',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });
                    await this.updateUser(false, true, false);
                }
                catch (error) {
                    console.error("canceled update");
                    return;
                }
            }
            // ask to resend invitation email if chaning email and user has not yet signed in before || status FORCE_CHANGE_PASSWORD
            else if(this.editUserDataPrev.email != this.formFieldsRoute.email){
                //* get user to check status
                let cognitoUser = await this.getCognitoUser(this.editUserDataPrev.email)
                this.allowResendEmail = cognitoUser.UserStatus === "FORCE_CHANGE_PASSWORD"
                //* open popup for change email user.
                this.showPopupUpdateEmail = true
            }
            else {
                await this.updateUser(false, false, false);
            }
        },

        /** Update a users information */
      async updateUser(logoutCurrent, logoutOther, sendEmail){
        this.loading = true
        try {

          // validate form
          await this.$refs.form.validate();
          var formattedEmail = this.cleanEmail(this.formFieldsRoute.email)

          // add user from cognito admin group
          if((this.formFieldsRoute.role.toLowerCase() == 'admin' && this.editPrevRole.toLowerCase() != 'admin') || (this.formFieldsRoute.permissionFullAccess && !this.editUserDataPrev.permissionFullAccess)){
            var addToGroup = await this.addUserToCognitoGroup(this.editPrevEmail.toLowerCase(), 'admin')
          }
          // remove user from cognito admin group
          else if((this.formFieldsRoute.role.toLowerCase() != 'admin' && this.editPrevRole.toLowerCase() == 'admin') || (!this.formFieldsRoute.permissionFullAccess && this.editUserDataPrev.permissionFullAccess)){
            var removeFromGroup = await this.removeUserFromCognitoGroup(this.editPrevEmail.toLowerCase(), 'admin')
          }

          // edit email in cognito 
          var attributes = [
            {
              Name: 'email',
              Value: formattedEmail
            },
            {
              Name: 'email_verified',
              Value: 'true'
            },
            {
                Name: 'custom:tenant_name',
                Value: this.companyName
            }
          ]

          if(this.editPrevEmail.toLowerCase() != formattedEmail){
                var cognitoUser = await this.getCognitoUser(formattedEmail)
                if(cognitoUser.statusCode && cognitoUser.statusCode != 200 && cognitoUser.code != 'UserNotFoundException' ){
                    var error = {
                        code: cognitoUser.code,
                        message: cognitoUser.message
                    }
                    throw(error)
                }
                var enabled = cognitoUser.Enabled
                if(enabled){
                    throw{
                        errors: [
                            { message: "User already exists with provided email"}
                        ]
                    }
                }
           }

          this.updateUserAttributes(this.editPrevEmail.toLowerCase(), attributes)

          var input = {
            ...this.formFieldsRoute,
            group: [
              this.group
            ]
          }
          input.email = formattedEmail
          input.phone = cleanPhoneNumber(input.phone, 'US')
          if(input.phone ==='') input.phone = null
          const result = await this.api(updateUser, {input: input})
          this.editUserModalOpen = false

          // resend activation email
          if(sendEmail){ 
            // resend cognito user
            let lowerEmail = this.formFieldsRoute.email.toLowerCase()
            let userId = this.formFieldsRoute.id
            try{
                await this.createCognitoUser(lowerEmail, userId, true)
                this.displayUserNotification({
                    type: 'success',
                    message: 'Password Resent',
                    title: 'Success'
                });
                this.showPopupUpdateEmail = false
            }catch(e){
                if(e?.response?.data?.message.includes('Resend not possible')){
                    e.response = 'Resend not possible. User has already changed their temporary password.'
                }
                console.error(e.response)
                this.displayUserError(e.response)
            }
          }
          // update zoho contact  
          if(!this.$store.getters.isZohoCrmDisabled){
            try{
            const resp = await this.updateZohoContact(result.data.updateUser)
            }catch(e){
            this.loading = false
            this.displayUserNotification({
                title: "Error",
                type: "error",
                message: e
            })
            }
          }
          this.displayUserNotification({
            type: 'success',
            title: 'Success',
            message: 'User Updated'
          });
          this.loading = false
          this.showPopupUpdateEmail = false
          if(logoutCurrent){
            await Auth.signOut()
            this.setSessionInfo(null)
            this.setUserInfo({tenant: {}})
            this.$router.replace({name: 'Login'})
            return
          }
          else if(logoutOther){
            // logout updated user using notifcation system
            let input = {
                title: "User Permissions Updated",
                description: 'Your user permissions have been updated by an account administrator.',
                isReadS: "false",
                clickAction: "LOGOUT",
                owner: this.formFieldsRoute.cognitoSub,
                group: this.formFieldsRoute.group.filter(group =>{
                    return group != 'system_admin'
                })[0],
                expirationTTL: Math.floor((new Date().getTime() + 90 * 24 * 60 * 60 * 1000) / 1000)              
            }
            await this.api(createNotification, {input} )
          }
          this.$router.go(-1)
        } catch(e) {
          console.error(e)
          e.message = "Please fill out all required fields."
          this.displayUserError(e)         
        }
        finally{
            this.loading = false
            this.showPopupUpdateEmail = false
        }
      },

        /** 
         * Create creates cognito user, adds user to tenant
         * group, adds user to DB */
        async createUser(){
            this.loading = true
            try {
                await this.$refs.form.validate()  
            }
            catch(e) {
                console.error(e)
                this.loading = false
                this.displayUserError("Please fill out all required fields and correct any errors.")
                return
            }

            // check for existing user
            try{
                var formattedEmail = this.cleanEmail(this.formFieldsRoute.email)
                var cognitoUser = await this.getCognitoUser(formattedEmail)
                if(cognitoUser.statusCode && cognitoUser.statusCode != 200 && cognitoUser.code != 'UserNotFoundException' ){
                    var error = {
                    code: cognitoUser.code,
                    message: cognitoUser.message
                    }
                    throw(error)
                }
                var enabled = cognitoUser.Enabled
                if(enabled){
                    throw{
                    errors: [
                        { message: "User already exists with provided email"}
                        ]
                    }
                }
            }
            catch(e) {
                console.error(e)
                
                // ignore user doesn't exist error in response
                if(e.errors){
                    this.displayUserError(e)
                    this.loading = false
                    return // only return if user exists
                }       
            }
            
            try{
                // Create DB record
                var input = {
                    ...this.formFieldsRoute,
                    userTenantId: this.tenantId,
                    role: '',
                    isOwner: false,
                    isCreator: false,
                    group: [
                        this.group
                    ]
                }   
                input.phone = cleanPhoneNumber(input.phone, 'US')
                if(input.phone ==='') delete input.phone
                const tenant = await this.api(getTenant, { id: input.userTenantId })
                if (!tenant.data.getTenant.users.items.length) {
                    if (!formattedEmail.startsWith('sysadmin-') && !formattedEmail.endsWith('@herasolutions.info')) {
                        input.isOwner = true
                        input.isCreator = true
                    }
                }
                
                input.email = formattedEmail
                // debugger
                const result = await this.api(createUser, {input: input})
                const userData = result;
                const userId = result.data.createUser.id

                try{
                    // create cognito user
                    cognitoUser = await this.createCognitoUser(formattedEmail, userId)
        
                    // Add user to group
                    var addToGroup = await this.addUserToCognitoGroup(formattedEmail, this.group)

                    if(this.formFieldsRoute.permissionFullAccess){
                        addToGroup = await this.addUserToCognitoGroup(formattedEmail, 'admin')
                    }
        
                    // get cognito user's sub
                    cognitoUser = await this.getCognitoUser(formattedEmail)
                    if(cognitoUser.statusCode && cognitoUser.statusCode != 200){
                        var error = {
                            code: cognitoUser.code,
                            message: cognitoUser.message
                        }
                        throw(error)
                    }
                    var sub = cognitoUser.UserAttributes.find((attr) => {
                        return attr.Name == "sub"
                    }).Value
                }
                catch(e){
                    if(userId){
                        await this.api(deleteUser, {input: {id: userId}})  
                    }
                    console.error(e)
                    this.loading = false
                    this.displayUserNotification({
                        title: "Error",
                        type: "error",
                        message: e
                    })
                    return
                }
                // update db record with cognito sub
                input = {
                    id: userId,
                    cognitoSub: sub,
                    group: [
                        this.group
                    ]
                }
                await this.api(updateUser, {input: input})

                // create zoho contact
                if(!this.$store.getters.isZohoCrmDisabled){
                    try{
                        const zohoCreateUserResponse = await this.createZohoContact(userData.data.createUser)
                        const input = {
                            id: userId,
                            group: [
                                this.group
                            ],
                            zohoCrmContactRecordId: zohoCreateUserResponse.data[0].details.id
                        }
                        const result = await this.api(updateUser, {input: input})
                    }catch(e){
                        console.error("ERROR: Zoho User Creation", e)
                    }
                }


                //Clean up app state
                this.userModalOpen = false
                this.$emit('loadData')
                this.$message({
                    type: 'success',
                    message: 'User Created'
                });
                this.loading = false
                this.$router.go(-1)
            } 
            catch(e) {
                console.error(e)
                this.loading = false
                this.displayUserError(e)        
            }
        },

        /** 
         * Gets a single cognito user
         * @param {string} username 
         * */
        async getCognitoUser(username){
            let apiName = 'AdminQueries';
            let path = '/getUser';
            let myInit = {
                queryStringParameters: {
                "username": username,
                }, 
                headers: {
                'Content-Type' : 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                } 
            }
            var result = await API.get(apiName, path, myInit);
            return result
        },

        /** 
       * Create creates cognito user, adds user to tenant
       * group, adds user to DB */
      async updateUserAttributes(username, attributes){
        let apiName = 'AdminQueries';
        let path = '/updateUser';
        let post = {
            body: {
              "username": username,
              "attributes": attributes 
            }, 
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        var result = await API.post(apiName, path, post);
        return result
      },


      /** 
       * Creates a new cognito user
       * @param {string} username 
       * */
      async createCognitoUser(username, userId, resend = false){
        let apiName = 'AdminQueries';
        let path = '/createUser';
        let post = {
            body: {
              "username": username,
              "userId": userId,
              "resend": resend,
              "tenantName": this.companyName
            }, 
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        var result = await API.post(apiName, path, post);
        return result
      },

      /** 
       * Disables a cognito user
       * @param {string} username 
       * */
      async disableCognitoUser(username){
        let apiName = 'AdminQueries';
        let path = '/disableUser';
        let post = {
            body: {
              "username": username,
            }, 
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        var result = await API.post(apiName, path, post);
        return result
      },

      /** 
       * Deletes a cognito user
       * @param {string} username 
       * */
      async deleteCognitoUser(username){
        let apiName = 'AdminQueries';
        let path = '/deleteUser';
        let post = {
            body: {
              "username": username,
            }, 
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        var result = await API.post(apiName, path, post);
        return result
      },
    

    /**
       * Adds the user to a group
       * @param {string} username
       * @param {string} groupname
       */
      async addUserToCognitoGroup(username, groupname) { 
        let apiName = 'AdminQueries';
        let path = '/addUserToGroup';
        let myInit = {
            body: {
              "username" : username,
              "groupname": groupname
            }, 
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        var result = await API.post(apiName, path, myInit);
      },

      /**
       * Removes the user to a group
       * @param {string} username
       * @param {string} groupname
       */
      async removeUserFromCognitoGroup(username, groupname) { 
        let apiName = 'AdminQueries';
        let path = '/removeUserFromGroup';
        let myInit = {
            body: {
              "username" : username,
              "groupname": groupname
            }, 
            headers: {
              'Content-Type' : 'application/json',
              Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            } 
        }
        var result = await API.post(apiName, path, myInit);
      },

      async updateZohoContact(user, contactStatus = "Active"){
        if(!user.zohoCrmContactRecordId) {
            const _result = createZohoContact(user);
            return _result;
        }
        let _payload = {
            id: user.zohoCrmContactRecordId,
            Hera_User_ID: user.id,
            First_Name: user.firstName,
            Last_Name: user.lastName,
            Email: user.email,
            Phone: user?.phone,
            Account_Name: {
            id: user.tenant.zohoCrmAccountRecordId
            },
            Is_Owner: user.isOwner,
            Contact_Status: contactStatus
        }
        try {
            let _result = await updateZohoAccountUser('update', _payload, this.displayUserError)

            //update DSP Owner
            if (user.isOwner) {
                let _payloadTenant = {
                    id: user.tenant.zohoCrmAccountRecordId,
                    DSP_Owner: {
                        id: user.zohoCrmContactRecordId,
                    }
                }
                try {
                    await updateZohoAccountTenant('update', _payloadTenant, this.displayUserError)
                } 
                catch (error) {
                    console.error('error updating zoho account', error)
                }
            }
            return _result
        } catch (error) {
            console.error('error updating zoho user', error)
        }       
      },

      async createZohoContact(user, contactStatus = "Active"){
        let _payload = {
            Hera_User_ID: user.id,
            First_Name: user.firstName,
            Last_Name: user.lastName,
            Email: user.email,
            Phone: user?.phone,
            Account_Name: {
            id: user.tenant.zohoCrmAccountRecordId
            },
            Is_Owner: user.isOwner,
            Contact_Status: contactStatus
        }
        try {
          let _result = await updateZohoAccountUser('create', _payload, this.displayUserError)
          //update DSP Owner
          if (user.isOwner) {
                let _payloadTenant = {
                    id: user.tenant.zohoCrmAccountRecordId,
                    DSP_Owner: {
                        id: _result.data[0].details.id,
                    }
                }
                try {
                    await updateZohoAccountTenant('update', _payloadTenant, this.displayUserError)
                } 
                catch (error) {
                    console.error('error updating zoho account', error)
                }
            }
          return _result
        } catch (error) {
            console.error('error updating zoho user', error)
        }
      },
    },
}
</script>

<style lang="scss">
#show-popup-update-email {
    .el-dialog__body {
        padding-top: 0;
        padding-bottom: .5rem;
    }

    .el-dialog {
        margin-top: 33vh !important;
    }
}

</style>