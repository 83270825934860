import {
    onCreateRouteByGroup,
    onUpdateRouteByGroup,
    onDeleteRouteByGroup
} from '../../subscriptions';
import store from '@/store'
import {
    initializeSubscription,
    disconnectSubscription,
    updateElement,
    updateRenderKey
} from '../helper'
import EventBus from '@/eventBus'

"use strict";
let subscriptionRoute = null

export const routeSubscribe = function(instance){
    const queries = {
        onCreateRouteByGroup,
        onUpdateRouteByGroup,
        onDeleteRouteByGroup
    }
    if(!store.state.userInfo?.tenant?.group) return
    const input = {
        group: store.state.userInfo.tenant.group
    }
    initializeSubscription(subscriptionRoute, queries, input, "subscriptionStore/handleRoute", instance)
}

export const config = function(subscriptions){
    const subscription = {}
    subscriptionRoute = subscriptions['route'] = {
        loadList: null,
        subscribe: routeSubscribe,
        unsubscribe: (instance) => disconnectSubscription(subscription, instance),
        callback: routeEventCallback,
        subscription
    }
}

export const routeEventCallback = function(subscriptionState, {data: route, eventName}){
    if(!route?.id) return
    
    EventBus.$emit('update-route-render', {
        target: "table-data-routes",
        dailyRoster: route.routeDailyRosterId,
        route,
        from: 'route',
        routeId: route?.id
    })
}