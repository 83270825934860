import {
    subscriptions,
    configureActionsForLists,
    configureActionsForNestedRecords,
    setList,
    mutateElement,
    handleElement,
    loadList,
    configureSubscriptions,
} from './helper'

import {
    lookupMapById,
    lookupMapByKey
} from '@/utilities/helpers'
import moment from 'moment-timezone'

configureSubscriptions([
    
    require('./lists/associateSubscriptions').config,
    require('./lists/vehicleSubscriptions').config,
    require('./lists/deviceSubscriptions').config,
    require('./lists/userSubscriptions').config,
    require('./lists/resourceUsageSubscriptions').config,
    require('./lists/companyNotificationSubscriptions').config,

    require('./nested/routeSubscriptions').config,
    require('./nested/replaceByRouteSubscriptions').config,
    require('./nested/dailyRosterSubscriptions').config,
    require('./nested/optionsCustomListsStaffSubscriptions').config,
    require('./nested/tenantSubscriptions').config,
    require('./nested/infractionSubscriptions').config,
    require('./nested/kudoSubscriptions').config,
    require('./nested/messengerSubscriptions').config,
    require('./nested/notificationSubscriptions').config,
    require('./nested/dailyLogSubscriptions').config,
    require('./nested/accidentSubscriptions').config,
    require('./nested/messageReadStatusSubscriptions').config,
    require('./nested/noteSubscriptions').config
])

export const subscriptionSetup = {
    subscribe: ()=>{
        const entries = Object.entries(subscriptions)
        entries.forEach(([key, pubs])=>{
            loadList(pubs)
            const instances = Object.entries(pubs.subscription)
            if(!instances.length || instances.some(([i, event]) => !event.socket)){
                pubs.subscribe()
            }
        })
        console.log("%clistening for mutation events.","background-color:rgba(200,250,0,.5)")
    },
    unsubscribe: ()=>{
        const entries = Object.entries(subscriptions)
        entries.forEach(([key,
            value])=>{
            const instances = Object.entries(value.subscription)
            if(instances.some(([i,
                event]) => event.socket)){
                value.unsubscribe()
            }
        })
    }
}

export const subscriptionStore = {//Store Module
    namespaced: true,
    state:()=>({
        vehicleList: [],
        associateList: [],
        messengerList: [],
        messageTemplates: [],
        deviceList: [],
        userList: [],
        infractionList: [],
        kudoList: [],
        dailyRosterCache: [],
        dailyLogCache: {},
        companyNotificationList: [],
        temporalRouteByDailyRosterId: {},
        temporalReplaceByRouteId: {},
        notesCache: [],
        resourceUsageList: []
    }),
    getters:{

        getVehicles: state => state.vehicleList,
        getActiveVehiclesList: (state) => {
            return state.vehicleList
                .filter(vehicle => vehicle.status?.toLowerCase() === 'active')
                .map(vehicle => ({
                    ...vehicle,
                    parkingSpace: vehicle.parkingSpace || { id: null, option: "" },
                    device: vehicle.device || { id: null, deviceName: "" }
                }));
        },
        getVehiclesLicensePlate90days: (state) => {
            const currentDate = new Date();
            // Calculate the date 90 days more
            const ninetyDaysMore = new Date(currentDate);
            ninetyDaysMore.setDate(currentDate.getDate() + 90);

            return state.vehicleList.filter(obj => {
                const expDate = new Date(obj.licensePlateExp);
                // Check if the expDate is within the last 90 days
                return expDate >= currentDate  && expDate <= ninetyDaysMore;
            });
        },
        getVehiclesWeekOldOdometerReadings: (state) => {
            const currentDate = moment().format('YYYY-MM-DD');
            // Calculate the date 1 week ago
            let weekAgo = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
            let twoWeekAgo = moment().subtract(2, 'weeks').format('YYYY-MM-DD');

            return state.vehicleList.filter(obj => {
                const expDate = obj.lastOdometerReadingDate;
                return ( expDate <= weekAgo && expDate > twoWeekAgo);
            });
        },
        getVehiclesTwoWeekOldOdometerReadings: (state) => {
            const currentDate = moment().format('YYYY-MM-DD');
            // Calculate the date 2 weeks ago
            let twoWeekAgo = moment().subtract(2, 'weeks').format('YYYY-MM-DD');
            
            return state.vehicleList.filter(obj => {
                const expDate = obj.lastOdometerReadingDate;
                return ( expDate <= twoWeekAgo);
            });
        },
        getVehiclesHighMileageOdometerReadings: (state) => {
            return state.vehicleList.filter( vehicle => {
                return parseInt(vehicle.mileage) >= 50000
            })
        },
        getAssociates: state => state.associateList,
        getAssociatesList: state => state.associateList.map(associate => {
            const { smsLastMessage, smsLastMessageTimestamp, ...restAssociate } = associate;
            return restAssociate;
        }),
        getDevices: state => state.deviceList,
        getUsers: state => state.userList,
        getCompanyNotifications: state => state.companyNotificationList,
        getDailyRoster: state => state.dailyRosterCache,
        
        getVehicleLookupMap: state => lookupMapById(state.vehicleList),
        getAssociateLookupMap: state => lookupMapById(state.associateList),
        getAssociatesMapByTransporterId: state => lookupMapByKey(state.associateList, 'transporterId'),
        getDeviceLookupMap: state => lookupMapById(state.deviceList),
        getUserLookupMap: state => lookupMapById(state.userList),
        getDailyRosterLookupMap: state => lookupMapById(state.dailyRosterCache),
        getMessengerLookupMap: state => lookupMapByKey(state.messengerList, 'staffId'),

        getActiveVehicles: state => state.vehicleList.filter(vehicle => vehicle.status?.toLowerCase() === 'active'),
        getActiveAssociates: state => state.associateList.filter(associate => associate.status?.toLowerCase() === 'active'),
        getActiveDevices: state => state.deviceList.filter(device => device.status?.toLowerCase() === 'active'),

        getInactiveAssociates: state => state.associateList.filter(vehicle => vehicle.status && vehicle.status.toLowerCase() !== 'active'),
        getInactiveVehicles: state => state.vehicleList.filter(associate => associate.status && associate.status.toLowerCase() !== 'active'),
        getInactiveDevices: state => state.deviceList.filter(device => device.status && device.status.toLowerCase() !== 'active'),

        getInfractionList: state => state.infractionList,
        getKudoList: state => state.kudoList,

        getMessageTemplates(state){
            if(state.messageTemplates.lastRenderKey && (new Date) - state.messageTemplates.lastRenderKey < 15000 ){
                return state.messageTemplates
            }
            return null
        },
        getResourceUsageList: state => state.resourceUsageList,
    },
    mutations: {
        setList,
        mutateElement,
        handleElement,
        clearTemporalRouteByDailyRosterId: (state, dailyRosterId) => {
            state.temporalRouteByDailyRosterId[dailyRosterId] = []
        },
        clearTemporalReplaceByRouteId: (state, routeId) => {
            state.temporalReplaceByRouteId[routeId] = []
        }
    },
    actions: {
        ...configureActionsForLists([
            { setList: 'setVehicleList', mutateElement: 'mutateVehicle', listName: 'vehicleList', entity: "vehicle" },
            { setList: 'setAssociateList', mutateElement: 'mutateAssociate', listName: 'associateList', entity: "associate" },
            { setList: 'setDeviceList', mutateElement: 'mutateDevice', listName: 'deviceList', entity: "device" },
            { setList: 'setUserList', mutateElement: 'mutateUser', listName: 'userList', entity: "user" },
            { setList: 'setResourceUsageList', mutateElement: 'mutateResourceUsage', listName: 'resourceUsageList', entity: "resourceUsage" },
            { setList: 'setCompanyNotificationList', mutateElement: 'mutateCompanyNotification', listName: 'companyNotificationList', entity: "companyNotification" },
        ]),
        ...configureActionsForNestedRecords([
            { handleElement: 'handleMessenger', entity: "messenger" },
            { handleElement: 'handleNotification', entity: "notification" },
            { handleElement: 'handleOptionsCustomListsStaff', entity: "optionsCustomListsStaff" },
            { handleElement: 'handleTenant', entity: "tenant" },
            { handleElement: 'handleInfraction', entity: "infraction" },
            { handleElement: 'handleKudo', entity: "kudo" },
            { handleElement: 'handleRoute', entity: "route" },
            { handleElement: 'handleReplaceByRoute', entity: "replaceByRoute" },
            { handleElement: 'handleDailyRoster', entity: "dailyRoster" },
            { handleElement: 'handleDailyLog', entity: "dailyLog" },
            { handleElement: 'handleAccident', entity: "accident" },
            { handleElement: 'handleMessageReadStatus', entity: "messageReadStatus" },
            { handleElement: 'handleNote', entity: "note" },
        ]),
        setMessengerList(context, list){
            context.commit('setList', { list, listName: "messengerList" })
        },
        setMessageTemplates(context, list){
            context.commit('setList', { list, listName: "messageTemplates" })
        }
    }
}