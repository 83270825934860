<template>
  <el-drawer
    ref="drawer"
    class="drawer-style"
    :visible.sync="visibility"
    custom-class="custom-drawer"
    :show-close="false"
    destroy-on-close
    :close-on-press-escape="false"
    :append-to-body="appendToBody"
    :wrapperClosable="false"
    with-header
    direction="rtl"
  >
    <!-- Title  -->
    <template slot="title">
      <slot name="drawerTitle">
        <div class="flex items-center justify-between">
          <el-row class="leading-normal p-1">
            <el-col :span="24">
              <span class="text-lg" v-html="title"></span>
            </el-col>
            <el-col :span="24" class="leading-3">
              <span class="text-xs">{{ subtitle }}</span>
            </el-col>
          </el-row>
          
          <div class="flex justify-end">
            <slot name="drawerIcons"></slot>
            <span class="flex items-center pl-2 md:pl-3 font-bold">
                <i @click="handleCloseDrawer()" class="el-icon-close md:mt-1 icon-button text-lg md:text-2xl"></i>
            </span>
          </div>
        </div>
        
      </slot>
    </template>
    <!-- Content -->
    <slot name="drawerContent" class="custom-drawer__content">
      <slot></slot>
    </slot>
    <!-- Footer -->
    <slot name="drawerfooter" class="custom-drawer__footer p-4 flex justify-end">
      <slot></slot>
    </slot>
    <el-row>
      <el-col :span="24" class="custom-drawer__footer p-4 flex justify-start">
        <slot name="drawerfooter-start" >
          <slot></slot>
        </slot>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="custom-drawer__footer p-4 flex justify-end">
        <slot name="drawerfooter-end" >
          <slot></slot>
        </slot>
      </el-col>
    </el-row>
  </el-drawer>
</template>
<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.onDrawerOpen()
  },
  beforeDestroy() {
    this.onDrawerClose()
  },
  methods: {
    handleCloseDrawer() {
      this.$emit('close-drawer')
    },
    onDrawerOpen() {
      this.$nextTick(() => {
        const drawerContent = this.$refs.drawer.$el.querySelector('.el-drawer__body');
        if (drawerContent) {
          drawerContent.addEventListener('scroll', this.handleScroll);
        }
      });
    },
    onDrawerClose() {
      const drawerContent = this.$refs.drawer.$el.querySelector('.el-drawer__body');
      if (drawerContent) {
        drawerContent.removeEventListener('scroll', this.handleScroll);
      }
    },
    handleScroll(event) {
      this.$emit('scroll', event)
    }
  },
};
</script>

<style scoped>
.icon-button {
  font-weight: 600;
  color: rgb(255, 255,255) !important;
}
.icon-button:hover {
  color: rgb(200, 200,200) !important;
}
.el-content-title__drawer{
  @apply w-full border-b font-bold mb-2 text-gray-600 !important;
}
.drawer-style .el-drawer__header {
  @apply bg-brand p-4 mb-0;
  color: #fff;
}
.el-drawer__body {
  position: relative;
}
</style>