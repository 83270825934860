<template>
  <div>
    <el-select
      ref="select"
      class="w-full"
      :class="{'dropdown-pill': isCustomListColourListEnabled && (selectedValue && selectedValue.id),'disabled-dropdown-pill' : disabled}"
      v-model="selectedValue"
      value-key="option"
      :size="size"
      :placeholder="placeholder"
      :popper-class="popperClass"
      popper-append-to-body
      :clearable="clearable"
      :filterable="filterable"
      :multiple="multiple"
      :disabled="disabled"
      @visible-change="handleVisibleChange"
      >
      <!-- Status Pill -->
      <template #prefix v-if="isCustomListColourListEnabled && (selectedValue && selectedValue.id)">
        <span
          class="font-bold rounded-full px-2 bg-opacity-10"
          :class="[selectedValue ? setPillCollor(selectedValue.pillColor) : '']">
          {{ selectedValue ? capitalizeText(selectedValue.option) : '' }}
        </span>
      </template>
      <template v-if="isCustomListColourListEnabled">
        <el-option
          v-if="isMobileDevice() && (selectedValue && selectedValue.id)"
          label="Unassigned"
          value=""
        >
          <span class="font-bold rounded-full px-4 bg-opacity-10">
            Unassigned
          </span>
        </el-option>
        <el-option
          v-for="option in selectOptions"
          :key="option.id"
          :label="option.option"
          :disabled="!option.isEnabled"
          :value="option"
        >
          <span
            class="font-bold rounded-full px-4 bg-opacity-10"
            :class="[setPillCollor(option.pillColor)]">
            {{ capitalizeText(option.option) }}
          </span>
        </el-option>
      </template>
      <!-- Other options -->
      <template v-else>
        <el-option
          v-for="(option, index) in selectOptions"
          :key="`${index}-${option.id}`"
          :label="option.option"
          :disabled="!option.isEnabled"
          :value="option"
        />
      </template>
      <div
        class="sticky bottom-0 bg-white px-4 pb-2 pt-2 cursor-pointer"
        @click="handleCustomListAddLinkAction">
        <p class="text-primary text-sm">{{ linkText }}</p>
      </div>
      <el-option class="hidden"></el-option>
    </el-select>

    <DrawerCustomList
      v-loading="loadingDataCustomList"
      :drawerVisibility="isDrawerOpen"
      :titleDrawer="customListDrawerTitle"
      :tableDataSelected="customList"
      @hideDrawer="isDrawerOpen = false"
      @reloadCustomList="handleCustomListsReload"
    />

    <el-dialog
      width="400px"
      title="Permission Needed"
      :center="true"
      :visible.sync="isModalOpen"
      :close-on-click-modal="false"
    >
      <div>
        <p class="text-center break-normal mb-4 leading-normal">
          You do not currently have permission to manage custom lists.
          Please speak with your manager to make a change to this list.
        </p>
        <div class="flex">
          <el-button
            size="small"
            type="primary"
            plain class="flex-1 rounded-lg shadow"
            @click="isModalOpen = false"
          >
            Ok
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DrawerCustomList from '@/views/Settings/CustomLists/components/DrawerCustomList';
import { API } from 'aws-amplify'

export default {
  name: 'CustomListsOptionsSelect',

  components: {
    DrawerCustomList,
  },

  props: {
    value: {
      type: [Object, Array, String],
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    size: {
      type: String,
      default: 'small'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    listType: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
    customListEnabledArgs: {
      type: Array,
      default: () => ([]),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validateOnSelect: {
      type: Object,
      default: () => ({}),
    },
    popperClass: {
      type: String,
      default: ''
    }
  },

  components: {
    DrawerCustomList,
  },

  data() {
    return {
      loading: false,
      isDrawerOpen: false,
      isModalOpen: false,
      dropdownOpen: false,
      selectedMultiple: this.value,
      loadingDataCustomList: false
    };
  },

  computed: {
    ...mapGetters([
      'getCustomListByType',
      'hasCustomListsManagement',
    ]),

    selectedValue: {
      get() {
        return this.multiple ? this.selectedMultiple.items : (this.value?.id ? this.value : null)
      },
      set(value) {
        const currentValue = this.multiple ? { items: this.selectedValue || [] } : this.selectedValue || { id: '' };
        const newValue = this.multiple ? { items: value || [] } : value || { id: '' };

        const afterValidationCallback = async (callBackItems = null, validatedDefaultVehicles) => {
          this.multiple ? this.selectedMultiple = callBackItems || newValue : this.$emit('input', callBackItems || newValue);
          if(this.dropdownOpen) return;
          this.$emit('old-value', currentValue);
          this.$emit('change', callBackItems || newValue, validatedDefaultVehicles || {});
        };

        if(Object.keys(this.validateOnSelect).length) {
          return this.$emit('validate-on-select', { newValue, currentValue, row: this.validateOnSelect } , afterValidationCallback )
        }
        afterValidationCallback()
      }
    },

    customList() {
      return this.getCustomListByType(this.listType);
    },

    customListOptions() {
      return this.customList?.options || [];
    },

    selectOptions() {
      return this.customListEnabled(this.customListOptions, ...this.customListEnabledArgs)
    },

    isValidSelectedValue() {
      return (this.value?.id && this.selectOptions.some(item => item.id === this.value?.id)) || true;
    },

    customListDrawerTitle() {
      return `Custom List Options: ${this.customList.listName || ''}`;
    },

    isCustomListColourListEnabled(){
      return this.customList.listDisplay === 'Status Pill'
    }
  },

  watch: {
    isValidSelectedValue(curr, prev) {
      if (this.value && this.value?.id && curr === false) {
        const defaultOption = this.selectOptions.find(item => item.default);
        if (!defaultOption) return;
        this.$emit('input', defaultOption.id);
      }
    },
  },

  methods: {
    async handleCustomListsReload() {
      try {
        this.loading = true;
        await this.loadCustomList();
      }
      catch(error) {
        this.printUserError(error);
        this.displayUserError(error);
      }
      finally {
        this.loading = false;
      }
    },

    async loadDataOptionsCustomList(row) {
      const {group, type} = row

      try {
          const apiName = "heraPublicApi"
          const path = "/query/option-custom-list"
          const { records } = await API.post(apiName, path, 
            { 
              body: { group, type}
            }
          )
          return records
      } catch (e) {
        console.error("Error: ", e);
        this.displayUserError(e);
        return []
      } finally {
        this.loadingDataCustomList = false
      }
    },

    handleVisibleChange(open) {
      if(!this.multiple) {
        if(!open) this.cleanupDropdownInstances();
        return;
      }
      this.dropdownOpen = open
      if(!open) this.$emit('change', this.selectedValue)
    },

    cleanupDropdownInstances() {
      const dropdowns = document.querySelectorAll('.dropdown-roster--status');
      if (dropdowns.length > 0) {
        dropdowns.forEach((dropdown, index) => {
          if (index > 0) {
            // Removes additional instances
            dropdown.parentNode.removeChild(dropdown); 
          }
        });
      }
    },

    async handleCustomListAddLinkAction() {
      this.$refs.select.blur();
      if (this.hasCustomListsManagement) {
        this.openCustomListsDrawer();
        this.loadingDataCustomList = true
        this.customList.options = await this.loadDataOptionsCustomList(this.customList)
        return;
      }
      this.openWaringDialog();
    },

    openCustomListsDrawer() {
      this.isDrawerOpen = true;
    },

    openWaringDialog() {
      this.isModalOpen = true;
    },
  },
}
</script>

<style>
.dropdown-pill {
  @apply border-solid border-1 rounded-lg border-gray-400;
}

.dropdown-pill input {
  visibility: hidden;
}

.dropdown-pill .el-input__prefix {
  @apply flex flex-wrap justify-center content-center whitespace-nowrap;
  line-height: 25px;
}

.disabled-dropdown-pill {
  background-color: #f5f7fa
}
</style>
